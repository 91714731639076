import React, { useState } from 'react'
import icon from '../../images/icons/icons.svg'
import { DatacenterPointWrapper } from './datacenter-styles'
import { motion } from 'framer-motion'

function DatacenterPoint({ x, y, location, open }) {
  const [hover, setHover] = useState(open)
  const popupAnimation = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.3
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: -60,
      transition: {
        duration: 0.3,
        delay: 0.2
      },
      transitionEnd: {
        display: 'none'
      }
    }
  }

  return (
    <DatacenterPointWrapper x={x} y={y} open={open}>
      <div
        className="data-point"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        role="button"
        tabIndex="0"
        name={location}
        aria-hidden="true"
      >
        <svg height="24" width="24">
          <use href={`${icon}#location`} />
        </svg>
        <motion.div
          className="data-popup"
          initial="exit"
          animate={open || hover ? 'enter' : 'exit'}
          variants={popupAnimation}
        >
          <p>{location}</p>
        </motion.div>
      </div>
    </DatacenterPointWrapper>
  )
}

export default DatacenterPoint
