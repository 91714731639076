import React, { useEffect, useState } from 'react'
import { DatacenterWrapper, DatacenterInnerWrapper } from './datacenter-styles'
import { Container } from '../common/common-styles'
import worldSVG from '../../images/illustrations/world.svg'
import PrimaryHeading from '../heading/primary-heading'
import DatacenterPoint from './datacenter-point'

function Datacenter() {
  const [index, setIndex] = useState(null)
  const title = 'Data Centers'
  const subtitle =
    'With Global standards and amazing performance coupled with scalability and control for as low as 45% compared with our competitors. Our datacenters are spread across the globe to provide the uninterrupted access to your servers.  '

  useEffect(() => {
    const random = setInterval(() => {
      let rnd = Math.floor(Math.random() * 3) + 1
      if (rnd !== index) setIndex(rnd)
    }, 3000)

    return () => {
      clearInterval(random)
    }
  })

  return (
    <DatacenterWrapper>
      <PrimaryHeading title={title} subtitle={subtitle} />
      <Container>
        <DatacenterInnerWrapper>
          <DatacenterPoint
            location="New York"
            x={22}
            y={22}
            open={index === 1}
          />
          <DatacenterPoint
            location="Florida"
            x={17.5}
            y={37}
            open={index === 2}
          />
          <DatacenterPoint
            location="Germany"
            x={50}
            y={20}
            open={index === 3}
          />
          <img src={worldSVG} alt="World Map Graphic" />
        </DatacenterInnerWrapper>
      </Container>
    </DatacenterWrapper>
  )
}

export default Datacenter
