const starterHosting = {
  1: {
    title: 'Starter Plan 3',
    discountPercent: 50,
    priceINR: 45,
    priceUSD: 1.5,
    featured: true,
    featureLabel: 'Most Popular',
    features: [
      'Best Selling Plan',
      '1 Domain',
      '3GB SSD Disk Space',
      'Unlimited Bandwidth',
      '25 email accounts',
      '10 MYSQL Databases',
      'Free SSL Certificate',
      '25 Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=6',
    footerText: 'Best Value'
  }
}

export default starterHosting
