import styled from 'styled-components'

export const DatacenterWrapper = styled.div``

export const DatacenterInnerWrapper = styled.div`
  position: relative;
  img {
    max-width: 970px;
    display: block;
    margin: 0 auto;
  }
`
export const DatacenterPointWrapper = styled.div`
  position: absolute;
  top: ${({ y }) => y + '%'};
  left: ${({ x }) => x + '%'};
  transform: translateX(-50%) translateY(-50%);
  div.data-point {
    position: relative;
    cursor: pointer;
    svg {
      fill: var(--blue);
      fill: ${({ open }) => open && 'var(--pink)'};
      transform: ${({ open }) => open && 'rotate(265deg)'};
      transition: transform 500ms ease, fill 500ms ease;
    }
    &:hover {
      svg {
        fill: var(--pink);
        transform: rotate(265deg);
      }
    }
    div.data-popup {
      position: absolute;
      bottom: 130%;
      background: var(--cool-white);
      white-space: nowrap;
      padding: 0.6rem;
      border-radius: 5px;
      box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.12);
      &:after {
        position: absolute;
        content: '';
        bottom: -7px;
        background: var(--cool-white);
        height: 15px;
        width: 15px;
        left: 5px;
        transform: rotate(45deg);
        border-radius: 2px;
      }
      p {
        color: var(--blue);
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
`
