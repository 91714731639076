import React from 'react'
import Pricing from '../pricing-components/pricing-group'
import starterPricingData from '../../../content/hosting/starter-hosting'
import PrimaryHeading from '../../heading/primary-heading'

function StarterPricing() {
  const title = 'Choose Your Plan'
  const subtitle =
    'Whether it’s for personal or business use, you need a web hosting service like Blue Geek Hosting to get your ideas online. No matter which plan you choose, you can count on us for reliability, security, and a stress-free experience.'

  return (
    <>
      <PrimaryHeading title={title} subtitle={subtitle} />
      <Pricing pricingContent={starterPricingData} />
    </>
  )
}

export default StarterPricing
